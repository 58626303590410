import React, { useTransition } from 'react';
import { Tooltip } from 'react-svg-tooltip';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import PropaneSVGEn from './propaneSVGEn';
import PropaneSVGFr from './propaneSVGFr';

function SvgWithBackgroundImage() {
  const cookies = new Cookies();
  const selectedLanguage = cookies.get('language')
    ? cookies.get('language')
    : 'en-US';

  const triggerRefHeatPump1 = React.createRef();
  const triggerRefHeatPump2 = React.createRef();
  const triggerRefFirePlace = React.createRef();
  const triggerRefPropaneTank = React.createRef();
  const triggerRefSpaceHeater = React.createRef();
  const triggerRefWaterHeater = React.createRef();
  const triggerRefBBQ = React.createRef();
  const triggerRefFurnaceBoiler = React.createRef();
  const triggerRefGasRange = React.createRef();
  const triggerRefGenerator = React.createRef();
  const [translation] = useTranslation();
  return (
    <>
      {selectedLanguage === 'fr-CA' ? (
        <PropaneSVGFr
          triggerRefHeatPump1={triggerRefHeatPump1}
          triggerRefHeatPump2={triggerRefHeatPump2}
          triggerRefFirePlace={triggerRefFirePlace}
          triggerRefPropaneTank={triggerRefPropaneTank}
          triggerRefSpaceHeater={triggerRefSpaceHeater}
          triggerRefWaterHeater={triggerRefWaterHeater}
          triggerRefBBQ={triggerRefBBQ}
          triggerRefFurnaceBoiler={triggerRefFurnaceBoiler}
          triggerRefGasRange={triggerRefGasRange}
          triggerRefGenerator={triggerRefGenerator}
        />
      ) : (
        <PropaneSVGEn
          triggerRefHeatPump1={triggerRefHeatPump1}
          triggerRefHeatPump2={triggerRefHeatPump2}
          triggerRefFirePlace={triggerRefFirePlace}
          triggerRefPropaneTank={triggerRefPropaneTank}
          triggerRefSpaceHeater={triggerRefSpaceHeater}
          triggerRefWaterHeater={triggerRefWaterHeater}
          triggerRefBBQ={triggerRefBBQ}
          triggerRefFurnaceBoiler={triggerRefFurnaceBoiler}
          triggerRefGasRange={triggerRefGasRange}
          triggerRefGenerator={triggerRefGenerator}
        />
      )}
      <div className="propaneHome-tooltip-container">
        <Tooltip triggerRef={triggerRefHeatPump1}>
          <foreignObject
            x="-20"
            y="-130"
            width="425px"
            height="100%"
            className="tooltip-heat-pump"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGHeatPump')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefHeatPump2} id="tooltip-heat-pump">
          <foreignObject x="-60" y="20" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGHeatPump')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefPropaneTank} id="tooltip-propane-tank">
          <foreignObject x="-50" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGPropaneTank')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefSpaceHeater} id="tooltip-space-heater">
          <foreignObject x="-30" y="-100" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGSpaceHeater')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefFirePlace} id="tooltip-fireplace">
          <foreignObject x="-30" y="-90" width="425px" height="100%">
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGFireplace')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefWaterHeater}>
          <foreignObject
            x="-100"
            y="-100"
            width="425px"
            height="100%"
            className="tooltip-water-heater"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGWaterHeater')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefFurnaceBoiler}>
          <foreignObject
            x="-110"
            y="-130"
            width="425px"
            height="100%"
            className="tooltip-furnace-boiler"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGFurnaceBoiler')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefGasRange}>
          <foreignObject
            x="-110"
            y="-90"
            width="425px"
            height="100%"
            className="tooltip-gas-range"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGGasRange')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefBBQ}>
          <foreignObject
            x="-175"
            y="-90"
            width="425px"
            height="100%"
            className="tooltip-bbq"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGBBQ')}
            </body>
          </foreignObject>
        </Tooltip>
        <Tooltip triggerRef={triggerRefGenerator}>
          <foreignObject
            x="-230"
            y="-90"
            width="425px"
            height="100%"
            className="tooltip-generator"
          >
            <body className="propaneHome-tooltip">
              {translation('PropaneSVGGenerator')}
            </body>
          </foreignObject>
        </Tooltip>
      </div>
    </>
  );
}

export default SvgWithBackgroundImage;
